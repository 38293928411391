var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "whistleblower-module"
  }, [_c('sygni-tabs', {
    ref: "sygniTabs",
    attrs: {
      "options": _vm.tabs,
      "legacyStyling": false,
      "defaultOption": _vm.tabsDefaultOption
    },
    on: {
      "click": _vm.selectTab
    },
    scopedSlots: _vm._u([{
      key: "beforeContent",
      fn: function fn() {
        return [_c('GenproxPageHeading', {
          attrs: {
            "title": "Whistleblower & Case Management",
            "description": "As Administrator you can update the company's profile and welcome message, which will be available to all Whistleblowers in your organisation. You will also need to appoint a Compliance Officer – entrusted person who will have access to all cases reported in your organization. Company Administrators can update case categories and monitor number of cases reported. However only the Compliance Officer will have access to all cases and can update their statuses, as well as interact with Whistleblowers (Signalists). Fund / Company token is un unchanged key that must be communicated to all parties within your organisation's Whistleblower process. This token is needed to log in anonymously to your organisation at <a href='https://incognitee.fundequate.com'>incognitee.fundequate.com</a>.",
            "breadcrumbsTitle": _vm.pageTitle
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function fn() {
              return [_vm.isWhistleblowerComplianceOfficer ? _c('sygni-rounded-button', {
                staticClass: "filled gn-primary",
                attrs: {
                  "plusIcon": true
                },
                on: {
                  "click": _vm.enableCreateCaseModal
                }
              }, [_vm._v("Create new case")]) : _vm._e()];
            },
            proxy: true
          }])
        }), _c('div', {
          class: ['hero mb-4', !_vm.showInstructions ? 'hero--off' : '']
        }, [_c('div', {
          staticClass: "container-fluid"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "hero__inner"
        }, [_c('div', {
          staticClass: "hero__container"
        }, [_vm.isWhistleblowerAdmin || _vm.isWhistleblowerComplianceOfficer ? _c('div', {
          staticClass: "fund-token"
        }, [_c('p', {
          staticClass: "fund-token__label"
        }, [_vm._v("Fund token")]), _c('sygni-input', {
          staticClass: "fund-token__input",
          attrs: {
            "value": _vm.fundToken,
            "disabled": true
          }
        }), _c('sygni-link-button', {
          staticClass: "gn-secondary",
          attrs: {
            "type": "simple"
          },
          on: {
            "click": _vm.copyFundToken
          }
        }, [_vm._v("Copy")])], 1) : _vm._e()])])])])]), _vm.isWhistleblowerComplianceOfficer ? _c('div', {
          staticClass: "hero__filters"
        }, [_c('whistleblower-filters', {
          ref: "whistleblowerFilters",
          attrs: {
            "perPage": _vm.perPage
          },
          on: {
            "filtersChange": _vm.applyFiltersEvent
          }
        })], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "cases",
      fn: function fn() {
        return [_c('whistleblower-table', {
          ref: "whistleblowerTable",
          attrs: {
            "show-page-number-options": true,
            "tableData": _vm.casesTable,
            "tablePerPageMutation": "whistleblower/setCasesTablePerPage",
            "setTableBusyMutation": "whistleblower/setCasesTableBusy",
            "busy": _vm.isBusy,
            "perPage": _vm.perPage,
            "getItemsAction": "whistleblower/getCases"
          },
          on: {
            "rowClick": _vm.openCaseModal
          }
        })];
      },
      proxy: true
    }, {
      key: "settings",
      fn: function fn() {
        return [_c('settings-panel', {
          ref: "settingsPanel"
        })];
      },
      proxy: true
    }, {
      key: "categories",
      fn: function fn() {
        return [_c('categories-panel', {
          ref: "categoriesPanel",
          on: {
            "viewCategory": _vm.viewCategory
          }
        })];
      },
      proxy: true
    }])
  }), _c('case-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openedCaseId,
      expression: "openedCaseId"
    }],
    attrs: {
      "isLoading": _vm.isCaseModalLoading,
      "caseId": _vm.openedCaseId
    },
    on: {
      "caseLoading": function caseLoading($event) {
        _vm.isCaseModalLoading = true;
      },
      "statusChanged": _vm.refreshCases,
      "caseLoaded": function caseLoaded($event) {
        _vm.isCaseModalLoading = false;
      },
      "close": _vm.closeCaseModal
    }
  }), _c('create-case-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCreateCaseModal,
      expression: "showCreateCaseModal"
    }],
    ref: "createCaseModal",
    on: {
      "close": _vm.closeCreateCaseModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }